import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;

  display: ${p => p.isOpen ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;

  background-color: rgba(0,0,0,0.5);
`;

export const Image = styled.img`
  max-width: 700px;
  max-height: 700px;
`;
