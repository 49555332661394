import React, {useState, useEffect} from 'react';
import {ReactMic} from 'react-mic';

// IMAGES
import escIcon from '../../assets/escIcon.png';
import confirmIcon from '../../assets/confirmIcon.png';

// STYLED
import {Container, Row, Button} from './styles';

function AudioRecorder({isOpen, close, saveAudio}) {

	const [cancel, setCancel] = useState(false);
	const [blob, setBlob] = useState(false);
	const [first, setFirst] = useState(true);

	const handleStop = (blob) => {
		setBlob(blob)
	}

	const handleCancel = () => {
		setCancel(true)
		close()
	}

	useEffect(() => {
		if (first) {
			return setFirst(false)
		}
		if (!cancel) {
			saveAudio(blob)
		} else {
			setCancel(false)
		}
	}, [cancel, first, blob, saveAudio])

	return <Container isOpen={isOpen}>
		<ReactMic record={isOpen} className="sound-wave" onStop={handleStop} strokeColor="#448757"
		          backgroundColor="#FFFFFF"/>
		<Row>
			<Button title="Enviar áudio" margin="0 30px 0 0" onClick={() => close()}>
				<img src={confirmIcon} alt=""/>
			</Button>
			<Button title="Cancelar envio" color="#B60404" onClick={() => handleCancel()}>
				<img src={escIcon} alt=""/>
			</Button>
		</Row>
	</Container>;
}

export default AudioRecorder;