import React from 'react';
import {useSelector} from 'react-redux';

// IMAGES
import clockIcon from '../../assets/clockIcon.png';
import checkIcon from '../../assets/checkIcon.png';
import doubleCheckIcon from '../../assets/doubleCheckIcon.png';
import errorIcon from '../../assets/errorIcon.png';

// STYLES
import {MessageContainer, MessageBody, Caret, Status, InfoText, Row} from './styles';

function Message({user, big, children, form, isImg, status, sender, timestamp}) {

	console.log("RENDER", user, big, children, form, isImg, status, sender, timestamp);

	let userMessage = '';
	if (user && (sender && sender.id) === user.id) userMessage = '__self';
	// const userMessage = user ? (sender && sender.id) === user.id ?  '__self' : false : ""

	const getStatusImage = () => {
		switch (status) {
			case 'delivered':
				return <img src={doubleCheckIcon} alt="" title="Enviado a todos com sucesso."/>;

			case 'sending':
				return <img src={clockIcon} alt="" title="Enviando..."/>;

			case 'partialDelivered':
				return <img src={checkIcon} alt="" title="Enviando a alguns membros com sucesso"/>;

			case 'error':
				return <img src={errorIcon} alt="" title="Erro ao enviar, tente novamente"/>;

			default:
				return <img src={clockIcon} alt="" title="Enviando..."/>;
		}
	}

	return (
		<MessageContainer user={userMessage}>

			{userMessage ? null : <Caret/>}

			<MessageBody isImg={isImg} isForm={form} big={big} userMessage={userMessage}>

				{
					sender && !userMessage
						?
						<Row margin="0 0 4px 0" display={"true"}>
							<InfoText>
								{!sender.name || sender._id === "__self" ? '' : sender.name}
							</InfoText>
						</Row>
						: null
				}

				{children}

				<Row isForm={form} isImg={isImg} status={status} display={timestamp || userMessage}>
					{
						timestamp
							? <InfoText>
								{timestamp}
							</InfoText>
							: null
					}
					{
						userMessage
							? <Status>
								{getStatusImage()}
							</Status>
							: null
					}
				</Row>

			</MessageBody>

			{userMessage ? <Caret user/> : null}

		</MessageContainer>
	)
}

export default Message;