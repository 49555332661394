import React from 'react';

import { Container, Image } from './styles';

function ImageModal({isOpen, close, img}) {
  return <Container onClick={close} isOpen={isOpen}>
    <Image src={img} />
  </Container>;
}

export default ImageModal;