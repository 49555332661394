import React from 'react';
import { Picker } from 'emoji-mart'
import 'emoji-mart/css/emoji-mart.css'

// STYLED
import { Container } from './styles';

function EmojiPicker({isOpen, close, setText}) {

  const handlePick = (e) => {
    setText(e.native)
    close()
  }

  return <Container isOpen={isOpen}>
    <Picker native={true} onSelect={(e) => handlePick(e)}/>
  </Container>;
}

export default EmojiPicker;