import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin: 0 0 10px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Input = styled.input`
  height: 0;
  width: 0;

  visibility: hidden;
  color: #448757;

  &:checked + label {
    &:after {
      transform: scale(4.2);
    } 
  }  
`;

export const Label = styled.label`
  margin: 0 0 4px;
  
  outline: none;
  user-select: none;
  color: ${p => p.theme.checkButtonColor || '#448757'};
  font-size: 14px;
  letter-spacing: 0.04rem;

  padding: 2px 4px;
  position: relative;
  overflow: hidden;
  top: ${p => p.value ? '3px' : '0'};  
  
  cursor: pointer;
  border-radius: .4rem;
  border: 1px solid ${p => p.theme.checkButtonColor || '#448757'};
  background: #fff;
  box-shadow: ${p => p.value ? 'none' : '0 3px 0 0 ' + (p.theme.checkButtonColor || '#448757')};  

  &:active {
    top: 3px;
    box-shadow: none;
  }
`;