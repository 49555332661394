import styled from 'styled-components';

export const Container = styled.div`
  width: 300px;
  height: ${p => p.isOpen ? '160px' : '0.5px'};
  overflow: hidden;

  position: absolute;
  bottom: 44px;
  left: 8px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  background-color: #FFFFFF;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  border-radius: 8px;
  opacity: ${p => p.isOpen ? '1' : '0'};
  transition: all ease 0.3s;
`;

export const Row = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  
`;

export const Button = styled.button`
  width: 40px;
  height: 40px;
  margin: ${p => p.margin || '0'};

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 100%;
  border: none;
  background-color: ${p => p.color || '#448757'};

  img {
    width: 20px;
    height: 20px;
  }
`;

