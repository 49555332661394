import { CHATINFO_START_CHAT, CHATINFO_FINISH_CHAT, CHATINFO_SAVE_MESSAGE } from '../constants/action-type'


export function startChat({user},id) {
    return({
        "type": CHATINFO_START_CHAT,
        "payload":{ user, id: id}
    })
}

export function finishChat(data) {
    return({
        "type": CHATINFO_FINISH_CHAT,
        "payload": data
    })
}

export function saveMessage(data) {
    return({
        "type": CHATINFO_SAVE_MESSAGE,
        "payload": {message: data}
    })
}

export function saveID(id) {
    return({
        "type": CHATINFO_SAVE_MESSAGE,
        "payload": id
    })
}
