import Axios from "axios";

const REGISTER_API_HOST = process.env.REACT_APP_REGISTER_API_HOST;

class Config {

    constructor() {
        this.map = {}
    }

    getConfig = async () => {
        let queryParams = new URLSearchParams(window.location.search);
        let key = queryParams.get("key");

        try {
            let config = {
                method: "get",
                url: `${REGISTER_API_HOST}/pbx/chat/list/client/services/key/${key}`,
            };

            let fetched = await Axios(config);

            return fetched.data;
        } catch (error) {
            console.error(error);
            return ''
        }
    };


}

let factory = new Config();

export default factory;