import styled from 'styled-components';

export const Container = styled.div`
  width: ${p => p.theme.chatWidth};
  min-width: 400px;
  height: ${p => p.theme.chatHeight};
  min-height: 400px;

  position: absolute;
  bottom: 140px;
  right: 30px;

  display: ${p => p.isOpen ? 'flex' : 'none'};
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  background-color: ${p => p.theme.chatBgcolor};
  border-radius: 8px 8px 0 0;
  opacity: 1;
  box-shadow: 0 5px 40px 0 rgba(0,0,0,.16)!important;

  &::placeholder {
    font: italic 18px/22px Roboto;
  }
`;

export const DragButton = styled.button`
  width: 60px;
  height: 60px;
  margin: 6px 18px -66px auto;

  z-index: 1;

  background-color: transparent;
  border: none;

  &:hover {
    cursor: auto;
  }

  img {
    max-width: 20px;
    max-height: 20px;

    &:hover {
      cursor: grab;
    }

    &:active {
      cursor: grabbing;
    }
  }
`;
