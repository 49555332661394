import React, {useRef, useEffect} from 'react';
import { useSelector } from "react-redux";
// COMPONENTS
import Message from '../Message';
import Attach from '../Attach';
import Form from '../Form';
import OptionsMessage from '../OptionsMessage';

// STYLED
import {Container, Anchor} from './styles';

// @TODO - Criar lógica para verificar se a mensagem é do próprio usuário ou não.
// E passar o resultado no parametro 'userMessage' para o componente Message
// Definir como virá o parametro status e passar na prop 'status' para o componente Message
// Definir como virá ação realizada no button submit do form

function Chat({user, messages, formButtonName, formTitle, optionsFormTitle, formSuccessText, optionsFormSuccessText}) {

	const chatState = useSelector(state => state.chat)

	const anchorElement = useRef()

	const messageRender = (message) => {

		if (!message) return null;
		switch (message.type) {

			case 'text':
			case 'message':
				return <Message user={user} sender={message.sender} timestamp={message.timestamp} userMessage={true} status={message.status}
				                key={message.id}>
					{message.value} 
				</Message>

			case 'form':
				return <Message user={user} sender={message.sender} timestamp={message.timestamp} userMessage={false}
				                status={message.status} key={message.id} form big>
					<Form formSuccessText={formSuccessText} formTitle={formTitle} message={message}
					      formButtonName={formButtonName}/>
				</Message>

			case 'button':
				return <Message user={user} sender={message.sender} timestamp={message.timestamp} userMessage status={message.status}
				                key={message.id} form>
					<OptionsMessage optionsFormSuccessText={optionsFormSuccessText} optionsFormTitle={optionsFormTitle}
					                message={message}/>
				</Message>

			case 'attachment':
				return <Message user={user} sender={message.sender} timestamp={message.timestamp} userMessage status={message.status}
				                isImg={message.value.type === 'image'} big={message.value.type === 'audio'} key={message.id}>
					<Attach user={user} item={message}/>
				</Message>

			default:
				break;
		}
	}

	const autoScroll = () => {
		anchorElement.current.scrollIntoView()
	}

	useEffect(() => {
		autoScroll()
	}, [chatState.messages])

	return <Container id={"msg1"}>
		{chatState.messages ? chatState.messages.map(messageRender) : null}
		<Anchor ref={anchorElement}/>
	</Container>;

}

export default Chat;
