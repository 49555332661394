import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 47px;
  margin: auto 0 0;
  overflow-y: auto;
  overflow-x: hidden;

  display: flex;
  justify-content: center;
  align-items: flex-start;

  background-color: white;
  border-radius: 0;
  box-shadow: ${p => p.focus ? '-2px -5px 40px 20px rgba(0,0,0,.05)' : '-2px -5px 10px 0 rgba(0,0,0,.05)'};
`;

export const Area = styled.textarea`
  min-width: calc(100% - 66px);
  height: 40px;
  max-height: 200px;
  overflow: auto;

  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 16px 68px 16px 30px;

  border: none;
  outline: none;
  border: none;
  resize: none;

  color: ${p => p.theme.textFontColor};
`;

export const IconBtn = styled.img`
  width: 20px;
  height: 20px;
  margin: ${p => p.margin || '13px -16px 0 0'};

  position: relative;

  border-radius: 100%;
  background-color: ${p => p.isOpen ? '#bebebe90' : 'transparent'};
  transition: all ease 0.3s;

  &:hover {
    cursor: pointer;
  }
`;

export const Send = styled.button`
  width: 40px;
  height: 23px;
  z-index: 2;
  margin: 13px 0 0 -60px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: ${p => p.theme.sendButtonBgcolor} 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: none;
  opacity: 1;
`;










