import React, { useRef, useState, useEffect } from 'react';

// IMAGES
import dragIcon from '../../assets/dragIcon.png';

// STYLES
import { Container, DragButton } from './styles';

function ChatWindow({children, isOpen, draggable, chatWindow, setChatWidth, setChatHeight}) {
  
  const Window = useRef()

  const [isDragging, setIsDragging] = useState(false)
  
  const [positionX, setPositionX] = useState(30)
  const [positionY, setPositionY] = useState(120)

  const [initialX, setInitialX] = useState(false)
  const [initialY, setInitialY] = useState(false)

  const handleStartDrag = (e) => {
    if (e.target.id === 'dragBtn') {
      setInitialX(e.clientX)
      setInitialY(e.clientY)
      setIsDragging(true)
    }
  }


  useEffect(() => {
    setChatWidth(Window.current.offsetWidth)
    setChatHeight(Window.current.offsetHeight)
  }, [isOpen])

  const handleDrag = (e) => {
    if(isDragging) {
      e.preventDefault()

      let xmovement = initialX - e.clientX
      let ymovement = initialY - e.clientY

      Window.current.style.right = (positionX + xmovement) + "px"
      Window.current.style.bottom = (positionY + ymovement) + "px"
    }
  }

  const handleStopDrag = (e) => {
    setIsDragging(false)

    let xmovement = initialX - e.clientX
    let ymovement = initialY - e.clientY

    setPositionX(p => p + xmovement)
    setPositionY(p => p + ymovement)
  }

  return <Container ref={Window} onMouseMove={e => handleDrag(e)} isOpen={isOpen}>
    {draggable ? <DragButton id="dragBtn" >
      <img id="dragBtn" onMouseDown={(e) => handleStartDrag(e)} onMouseMove={e => handleDrag(e)} onMouseUp={(e) => handleStopDrag(e)} src={dragIcon} alt=""/>
    </DragButton> : null}
    {children}
  </Container>;
}

export default ChatWindow;