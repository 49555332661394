import manager from './socket';

export function endChat(clientId, customerId) {
    const socketIO = manager.getSocket(clientId, customerId);
    socketIO.emit("end-attendance");
    onEndChat(clientId);
}

function onEndChat(clientId, customerId) {
    const socketIO = manager.getSocket(clientId, customerId);
    /**
     * @TODO add on endAttendance handler
     */
    socketIO.on("end-attendance", e => {
        return e;
    });
}
