import React, {useState, useEffect, useRef} from "react";

// IMAGES
import chatIcon from "../../assets/chatIcon.svg";
import escIcon from "../../assets/escIcon.png";

// COMPONENTES
import ChatWindow from "../ChatWindow";
import Header from "../Header";
import Textarea from "../Textarea";
import Chat from "../Chat";
import VideoCall from "../VideoCall";
import IncomingCall from "../IncomingCall";

// import tempData from '../../assets/data';

// STYLES

import {Container, BallonBody, Caret, Powered} from "./styles";

function Ballon({
                    socketIO,
                    user,
                    clientId,
                    messages,
                    chatId,
                    socketEvent,
                    socketEmit,
                    icon,
                    placeholder,
                    title,
                    clientLogo,
                    draggable,
                    formButtonName,
                    enableEmoji,
                    enableAttach,
                    enableRecord,
                    formTitle,
                    formSuccessText,
                    optionsFormTitle,
                    optionsFormSuccessText,
                    exportText,
                    popupText,
                }) {
    // MOCKUP - Implementar conexão para receber as messages em real time que deve substituir este mock

    const [isOpen, setIsOpen] = useState(false);
    const [isVideoCallOpen, setIsVideoCallOpen] = useState(false);
    const [incomingCall, setIncomingCall] = useState(false);
    const [alreadyOnCall, setAlreadyOnCall] = useState(true);
    const [chatHeight, setChatHeight] = useState(400);
    const [chatWidth, setChatWidth] = useState(400);
    const [openConnection, setOpenConnection] = useState(false);

    const acceptCall = () => {
        setIsVideoCallOpen(true);
        setIncomingCall(false);
        setAlreadyOnCall(false);
    };

    useEffect(() => {
        emitResize(chatWidth, chatHeight);
    }, [chatHeight, chatWidth]);

    useEffect(() => {
        if (!user) return;
        let socketData = {
            clientId,
            user: {
                id: user.id,
                name: user.name,
            },
            key: chatId
        };
        if (isOpen && !openConnection && typeof socketEmit === "function") {
            setOpenConnection(true);
            socketEmit("isOpen", socketData);
        }
    }, [ socketEmit, openConnection, isOpen, user ]);

    const chatWindow = useRef();

    function emitResize(width, height) {
        if (!window.parent) return;
        window.parent.postMessage(
            {
                width: width ? width + 30 : 100,
                height: height ? height + 140 : 100,
            },
            "*"
        );
    }

    //ERRO
    useEffect(() => {
        placeEventListner()
        
    },[])

    function placeEventListner() {
        if (!window) return

        window.addEventListener('message', function(event) {
            if(event.data.type === 'storageMessage'){
            }
        }, false);
    }

    return (
        <>
            <Container isOpen={isOpen}>
                <BallonBody
                    onClick={() => (emitResize(), setIsOpen((state) => !state))}
                    isOpen={isOpen}
                >
                    <img src={isOpen ? escIcon : icon || chatIcon} alt=""/>
                </BallonBody>
                <Caret/>
            </Container>

            <ChatWindow
                ref={chatWindow}
                setChatWidth={setChatWidth}
                setChatHeight={setChatHeight}
                draggable={draggable}
                isOpen={isOpen}
            >
                <Header
                    setIsOpen={setIsOpen}
                    openVideoCall={() => setIsVideoCallOpen(true)}
                    popupText={popupText}
                    exportText={exportText}
                    title={title}
                    clientLogo={clientLogo}
                    user={user}
                    clientId={clientId}
                    chatId={chatId}
                />

                <Chat
                    user={user}
                    formSuccessText={formSuccessText}
                    optionsFormSuccessText={optionsFormSuccessText}
                    formButtonName={formButtonName}
                    formTitle={formTitle}
                    optionsFormTitle={optionsFormTitle}
                    // messages={messages}
                />

                <Textarea
                    user={user}
                    clientId={clientId}
                    chatId={chatId}
                    socketEmit={socketEmit}
                    placeholder={placeholder || "Digite..."}
                    enableEmoji={enableEmoji}
                    enableAttach={enableAttach}
                    enableRecord={enableRecord}
                />
                <Powered>
                    <a
                        rel="noopener noreferrer"
                        href="https://www.55pbx.com/"
                        target="_blank"
                    >
                        Powered by 55PBX
                    </a>
                </Powered>
            </ChatWindow>

            {/* <VideoCall
                isOpen={isVideoCallOpen}
                close={() => setIsVideoCallOpen(false)}
                setAlreadyOnCall={setAlreadyOnCall}
                alreadyOnCall={alreadyOnCall}
            /> */}

            <IncomingCall
                isOpen={incomingCall && isOpen}
                close={() => setIncomingCall(false)}
                acceptCall={acceptCall}
                callerName="Nome Chamador"
            />
        </>
    );
}

export default Ballon;
