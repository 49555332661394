import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 70px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px;

  background-color: ${p => p.theme.headerBgcolor};
  border-radius: 8px 8px 0 0;

  font: bold 18px/24px sans-serif;
  color: ${p => p.theme.titleFontColor};

  img {
    max-width: 40px;
    max-height: 40px;
    margin: 0 10px 0 0;
  }
`;

export const ActionButton = styled.button`
  margin: 0 0 0 auto;
`;

export const Icon = styled.button`
  margin: ${p => p.margin || 0};

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: transparent;
  border: none;

  img {
    width: 22px;
    margin: 0;
  }
`;






