import React, { useState } from 'react';
import Axios from 'axios';

// IMAGES
import loader from '../../assets/loader.svg';
import formSentIcon from '../../assets/formSentIcon.png';

// COMPONENTS
import FormRender from '../FormRender';

import { Container, FormContainer, FormTitle, FormButton, SentImage } from './styles';

function Form({handleSubmit, message, formButtonName, formTitle, formSuccessText}) {

  const [isAnswered, setIsAnswered] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)

  const submitForm = async (e) => {
    let result = [...e.target.elements]
    let data = []

    result = result.filter(item => item.type !== 'button' && item.type !== 'submit')
    result.forEach(item => data.push({name: item.name, value: item.type === 'checkbox' ? item.checked : item.value}))

    e.preventDefault()
    setIsLoading(true)
    setIsDisabled(true)

    try {
      await Axios.post('https://run.mocky.io/v3/22d37272-24b5-489a-ab92-9bf6f1a98818?mocky-delay=1s', data)

      setIsLoading(false)
      setIsAnswered(true)
      
    } catch (e) {
      console.error(e)
      alert('Um erro ocorreu ao tentar enviar seu formulário, por favor tente novamente ou entre em contato com o suporte.')
      setIsLoading(false)
    }
  }

  return <Container>
    <FormTitle>
      {formTitle || 'Formulário'}
    </FormTitle>

    <FormContainer onSubmit={e => submitForm(e)}>

      {message.value.map((item, index) => {
        return <FormRender disabled={isDisabled} key={item.id || index} item={item}/>
      })}

     { isLoading 
      ? <SentImage src={loader} alt=""/>
      : isAnswered 
        ? <>
            <SentImage src={formSentIcon} alt=""/>
            <p style={{margin: '10px auto'}}>{formSuccessText || 'Formulário enviado com sucesso.'}</p>
          </>
        : <FormButton type="submit">
            {formButtonName || 'ENVIAR'}
          </FormButton>
      }

    </FormContainer>
  </Container>;
}

export default Form;