const INITIAL_STATE = {
	name: null,
	id: null,
	token: null,
	isSigned: null
};

const users = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SINGIN':
      return { ...state, name: action.payload.name, id: action.payload.id, token: action.payload.token, isSigned: true };
    case 'LOGOUT':
      return { ...state, name: '', id: '', token: '', isSigned: '' };
    case 'SET_TOKEN':
      return { ...state, token: action.payload};
    default:
      return state;
  }
}

export default users;