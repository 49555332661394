import React, { useState } from 'react';

import { Container, Input, Label } from './styles';

function CheckButton({item, onClick}) {
  
  const [value, setValue] = useState(false)
  
  const handleClick = (e) => {
    value === false ? setValue(item.value) : setValue(false)
    onClick(item.value, item.description)
  }

  return <Container>
    <Input type="checkbox" id={item.id} onClick={handleClick} />
    <Label value={value} htmlFor={item.id}>
      {item.description}
    </Label>
  </Container>
}

export default CheckButton;