import React, { useState } from 'react';
import Axios from 'axios';

// IMAGES
import loader from '../../assets/loader.svg';
import formSentIcon from '../../assets/formSentIcon.png';

// COMPONENTS
import CheckButton from '../CheckButton';

import { Container, FormContainer, FormTitle, SentImage } from './styles';

function OptionsMessage({message, optionsFormTitle, optionsFormSuccessText}) {

  const [selected, setSelected] = useState(false)
  const [selectedName, setSelectedName] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleClick = async (value, description) => {
    setIsLoading(true)

    try {
      await Axios.post('https://run.mocky.io/v3/22d37272-24b5-489a-ab92-9bf6f1a98818?mocky-delay=1s', value)
      alert('Usando um post fake, deve ser implementado um envio aqui')

      setIsLoading(false)
      setSelected(value)
      setSelectedName(description)
      
    } catch (e) {
      console.error(e)
      alert('Um erro ocorreu ao tentar enviar sua opção, por favor tente novamente ou entre em contato com o suporte.')
      setIsLoading(false)
    }
  }

  if (selected) {
    return <Container>
      <FormTitle>
        { optionsFormTitle || 'Opções' }
      </FormTitle>

      <FormContainer>

        <SentImage src={formSentIcon} alt=""/>
        <p style={{textAlign: 'center'}}>{optionsFormSuccessText || 'Você escolheu a opção'} '{selectedName}'</p>

      </FormContainer>
    </Container>;
  }

  if (isLoading) {
    return <Container>
      <FormTitle>
       { optionsFormTitle || 'Opções' }
      </FormTitle>

      <FormContainer>

       <SentImage src={loader} alt=""/>

      </FormContainer>
    </Container>;
  }

  return <Container>
    <FormTitle>
     { optionsFormTitle || 'Opções' }
    </FormTitle>

    <FormContainer>

    {message.value.map((item, index) => {
      return <CheckButton onClick={handleClick} key={item.id || index} item={item} />
    })}  

    </FormContainer>
  </Container>;
}

export default OptionsMessage;