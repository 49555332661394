import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 0 0 10px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Icon = styled.img`
  height: 20px;

  &:hover {
    cursor: pointer;
  }
`;

export const Window = styled.div`
  height: ${p => p.isOpen ? '100px' : '0.01px'};
  width: 180px;
  margin: 144px 0 0 -140px;

  position: absolute;
  z-index: 1;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: ${p => p.isOpen ? '12px 0' : '0'};
  overflow-y: auto;

  border-radius: 8px;
  background-color: white;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  opacity: ${p => p.isOpen ? '1' : '0'};
  transition: all ease 0.3s;
`;

export const Option = styled.button`
  width: 100%;
  height: 22px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 8px;

  background-color: transparent;
  border: none;
  transition: all ease 0.3s;

  font-size: 12px;

  &:hover {
    background-color: #BEBEBE90;
  }
`;



















