import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  bottom: 26px;
  right: 30px;

  transition: all ease 0.3s;
  transform: ${(p) => (p.isOpen ? "rotate(-90deg)" : "rotate(30deg)")};
`;

export const BallonBody = styled.button`
  width: 70px;
  height: 70px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 100%;
  background-color: ${(p) => p.theme.iconBgcolor};
  border: none;
  outline: none;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  &:hover + div {
    opacity: 0.8;
  }

  img {
    max-width: 30px;
    max-height: 26px;

    transition: all ease 0.3s;
    transform: ${(p) => (p.isOpen ? "rotate(90deg)" : "rotate(-30deg)")};
  }
`;

export const Caret = styled.div`
  width: 0;
  height: 0;
  margin: 0 -76px 0 0;

  position: absolute;

  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid ${(p) => p.theme.iconBgcolor};

  transition: all ease 0.3s;
`;

export const Powered = styled.div`
  width: ${(p) => p.theme.chatWidth};
  min-width: 400px;
  margin: ${(p) =>
      p.theme.chatHeight && p.theme.chatHeight.split("px")[0] > 399
        ? p.theme.chatHeight
        : p.theme.chatHeight
        ? "400px"
        : "600px"}
    0 0 0;

  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0;

  background-color: #f7f2f2;
  border-radius: 0 0 8px 8px;

  a {
    margin: 0;

    font-size: 12px;
    color: #448757ee;
    text-decoration: none;

    &:hover {
      cursor: pointer;
    }
  }
`;
