import React, {useState, useEffect} from 'react';
import {Notification} from './styles';

function ChatNotification() {

	const [status, setStatus] = useState(null);

	const statusFn = {
		typing () {
			return <Notification type={'text'}>Typing...</Notification>
		},
		recording() {
			return <Notification type={'audio'}>Recoding...</Notification>
		},
		sendingFile() {
			return <Notification type={'file'}>Sending file...</Notification>
		}
	}

	setStatus(null);

	return status? statusFn[status]: null;
}

export default ChatNotification;