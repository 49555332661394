import React, {useState} from 'react';

// MATERIAL UI
import {withStyles} from '@material-ui/core/styles';
import {green} from '@material-ui/core/colors';
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import MaterialCheckbox from '@material-ui/core/Checkbox';
import DateFnsUtils from '@date-io/date-fns';

import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
	KeyboardTimePicker,
} from '@material-ui/pickers';

function FormRender({item, disabled}) {

	let {
		data, name, type, required,
		label, placeholder, dependsOn,
		items = [],
		value = "",
		onChange = () => {
		},
		errors = [],
		getValue
	} = item;

	const Checkbox = withStyles({
		root: {
			color: green[400],
			'&$checked': {
				color: green[600],
			},
		},
		checked: {},
	})((props) => {
		let {'error': _, ...newProps} = props;
		return <MaterialCheckbox color="default" {...newProps} />
	});

	const setDefaultValue = () => {
		if (item.type === 'date' || item.type === 'time') {
			return new Date();
		} else {
			return '';
		}
	}

	const [inputValue, setInputValue] = useState(value || setDefaultValue())

	let id = `form-${name}`;
	required = !!required;
	label = label["pt-BR"];
	placeholder = placeholder && placeholder["pt-BR"];

	let inputHtml = null;

	// let labelHtml = <p htmlFor={id}>{label}</p>;

	let placeholderHtml = placeholder
		? <FormHelperText>{placeholder}</FormHelperText>
		: null;

	let errored = errors.length > 0;

	if (!getValue) getValue = e => {
		setInputValue(e)
	};

	switch (type) {
		case "number": {
			inputHtml = (<Input
				id={id}
				required={required}
				type="number"
				name={name}
				value={inputValue}
				//error={"false"}
				onChange={e => onChange(getValue(e.target.value))}
				disabled={disabled ? 'disabled' : null}
			/>);
			break;
		}
		case "date": {
			let now = new Date().toISOString().split("T");
			if (!value) value = now[0];
			value = `${value}T${now[1]}`;

			// labelHtml = null;
			inputHtml = (
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<KeyboardDatePicker
						disableToolbar
						variant="inline"
						format="dd/MM/yyyy"
						margin="normal"
						id={id}
						label={label}
						value={inputValue}
						onChange={date => {
							let value;
							try {
								value = date.toISOString().split("T")[0];
							} catch (err) {
								value = undefined;
							}
							onChange(getValue(value));
						}}
						KeyboardButtonProps={{
							'aria-label': 'Selecionar a data',
						}}
						error={errored}
						required={required}
						name={name}
						disabled={disabled}
					/>
				</MuiPickersUtilsProvider>
			);
			break;
		}
		case "time": {
			if (!value) value = new Date().toISOString().split("T")[1];
			value = new Date().toISOString().split("T")[0] + "T" + value;

			// labelHtml = null;
			inputHtml = (
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<KeyboardTimePicker
						autoOk
						ampm={false}
						variant="inline"
						orientation="landscape"
						id={id}
						label={label}
						value={inputValue}
						onChange={date => {
							let value;

							try {
								value = date.toISOString();
							} catch (err) {
								value = undefined;
							}

							onChange(getValue(value));
						}}
						error={errored}
						required={required}
						name={name}
						disabled={disabled}
					/>
				</MuiPickersUtilsProvider>
			);
			break;
		}
		case "select": {
			inputHtml = (
				<Select
					id={id}
					value={inputValue}
					onChange={e => onChange(getValue(e.target.value))}
					error={errored}
					required={required}
					name={name}
					disabled={disabled}
				>
					{
						(dependsOn ? (items[data[dependsOn]] || []) : items).map(i => (
							<MenuItem key={i.value} value={i.value}>{i.label}</MenuItem>
						))
					}
				</Select>
			);
			break;
		}
		case "hidden":
			inputHtml = (
				<input
					id={id}
					type="hidden"
					value={inputValue}
					name={name}
				/>
			);
			// labelHtml = null;
			placeholderHtml = null;
			break;
		case "boolean":
			// labelHtml = null;
			placeholderHtml = null;
			inputHtml = (
				<FormControlLabel control={
					<Checkbox
						id={id}
						onChange={e => onChange(getValue(e.target.checked))}
						error={errored}
						checked={inputValue}
						name={name}
						disabled={disabled}
					/>
				} label={label}/>
			);
			break;
		case "text":
		default: {
			inputHtml = (<Input
				id={id}
				required={required}
				type="text"
				value={inputValue}
				onChange={e => onChange(getValue(e.target.value))}
				error={errored}
				name={name}
				disabled={disabled}
			/>);
		}
	}

	return (
		<FormControl>
			{<p htmlFor={id}>{label}</p>}
			{inputHtml}
			{placeholderHtml}
		</FormControl>
	);

};

export default FormRender;