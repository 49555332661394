import React from 'react';

// IMAGES
import callingIcon from '../../assets/callingIcon.svg';
import phoneIcon from '../../assets/phoneIcon.png';

import { Container, Modal, CallImg, Effect, Buttons } from './styles';

function IncomingCall({isOpen, callerName, close, acceptCall}) {

  const handleAccept = () => {
    alert('Ação de atender chamada')
    acceptCall()
  }

  const handleReject = () => {
    alert('Ação de rejeitar chamada')
    close()
  }

  return <Container isOpen={isOpen}>
    <Modal>
      <CallImg src={callingIcon} alt=""/>
      <Effect />
      <p>Recebendo chamada de </p>
      <h2>{callerName || 'Usuário'}</h2>

      <Buttons>
        <button onClick={() => handleAccept()} style={{backgroundColor: '#448757BB'}}>
          <img src={phoneIcon} alt=""/>
        </button>
        <button onClick={() => handleReject()} style={{backgroundColor: '#ff0000BB'}}>
          <img style={{transform: 'rotate(136deg)'}} src={phoneIcon} alt=""/>
        </button>
      </Buttons>
    </Modal>
  </Container>;
}

export default IncomingCall;