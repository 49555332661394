import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage'
import createRootReducer from '../reducers'
import chatInfo from '../reducers/chatInfo'

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
    key: 'root',
    storage,
    
};


const persistedReducer = persistReducer(persistConfig, createRootReducer())

const store = createStore(
    persistedReducer, 
    storeEnhancers(
        applyMiddleware(thunk), 
    )
)
const persistor = persistStore(store)

export { store, persistor }