import styled from 'styled-components';

export const Container = styled.div`
  display: ${p => p.isOpen ? 'flex' : 'none'}
`;

export const MainVideo = styled.canvas`
  width: 300px;
  height: 600px;

  position: absolute;
  bottom: 140px;
  right: 484px;

  background-color: #00000090; 
  border-radius: 8px;
`;

export const Controls = styled.div`
  width: 300px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: absolute;
  bottom: 160px;
  right: 484px; 

  p {
    margin: 0;
    
    color: #fff;
    font-size: 12px;
    font-weight: bold;
  }

  h5 {
    margin: 8px 0 0;

    color: #fff;
    font-size: 12px;
    font-weight: normal;
  }

  h4 {
    margin: 0 0 64px;

    color: #fff;
    font-size: 18px;
    font-weight: bold;
  }
`;

export const Buttons = styled.div`
  margin: 26px 0 0;
  width: 90%;

  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const ActionBtn = styled.button`
  width: 50px;
  height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 8px;
  border: none;
  background-color: ${p => p.bg || '#FFFFFF50'};

  &:hover {
    opacity: 0.7;    
  }

  img {
    max-width: 30px;
  }
`;

export const CallImg = styled.img`
  width: 140px;
  margin: 0 0 38px;
  z-index: 1;

  animation-name: ring;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;

  @keyframes ring {
    from {transform: rotate(0deg)}
    to {transform: rotate(12deg)}
    to {transform: rotate(-12deg)}
  }
`;

export const Effect = styled.div`
  width: 160px;
  height: 160px;
  margin: 0 0 194px;

  position: absolute;

  background-color: #448757BB;
  border-radius: 100%;

  animation-name: grow, blink;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes blink {
    from {opacity: 1}
    to {opacity: 0.2} 
  }

  @keyframes grow {
    from {transform: scale(0.2,0.2)}
    to {transform: scale(1,1)} 
  }
`;

export const Portrait = styled.div`
  width: 75px;
  height: 100px;
  overflow: hidden;

  position: absolute;;
  bottom: 627px;
  right: 499px;

  border-radius: 8px;
  border: 0.2px solid #FFFFFF60;

  &:hover {
    cursor: pointer;
  }

  canvas {
    width: 100%;
    height: 100%;

    background-color: blue;
    border-radius: 8px;
  }
`;




