import React, {useRef, useEffect, useState} from "react";
import autosize from "autosize";
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {  saveMessage } from '../../actions/chatInfo'

// IMAGES
import emojiIcon from "../../assets/emojiIcon.png";
import attachIcon from "../../assets/attachIcon.png";
import micIcon from "../../assets/micIcon.png";
import sendIcon from "../../assets/sendIcon.svg";

// COMPONENTS
import EmojiPicker from "../EmojiPicker";
import AudioRecorder from "../AudioRecorder";
// import ChatNotification from '../ChatNotification';

// STYLED
import {Container, Area, IconBtn, Send} from "./styles";

const INTEGRATOR_API_HOST = process.env.REACT_APP_INTEGRATOR_API;

function Textarea({
                      user,
                      clientId,
                      chatId,
                      socketEmit,
                      store,
                      placeholder,
                      enableEmoji,
                      enableAttach,
                      enableRecord,
                  }) {
    const textarea = useRef();
    const fileInput = useRef();

    const [isRecording, setIsRecording] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [isMicBlocked, setIsMicBlocked] = useState(false);

    const [isEmojiOpen, setIsEmojiOpen] = useState(false);
    const [isActive, setIsActive] = useState();
    const [text, setText] = useState("");

    const dispatch = useDispatch()

    useEffect(() => {
        autosize(textarea.current);
    }, []);

    useEffect(() => {
        setUpMic();
    }, []);

    const sendMessage = (e) => {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }
        if (!user)
            return alert("Cannot send message without an authenticated user");
        if (!text.trim()) return;

        let message = {
            id: +new Date(),
            clientId: clientId,
            type: "text",
            value: text,
            key: chatId,
            sender: {
                id: user.id,
                name: user.name,
            },
            timestamp: getTimeStamp(),
            status: "delivered",
        };
        //'__self' 
        socketEmit("message", message);
        saveMessage(message)
        //dispatch
        setText("");
        setIsTyping(false);
        textarea.current.style.height = "45px";
    };

    // AUDIO FUNCS
    const setUpMic = () => {
        navigator.getUserMedia(
            {audio: true},
            () => {
                setIsMicBlocked(false);
            },
            () => {
                setIsMicBlocked(true);
            }
        );
    };

    const startRecording = () => {
        if (isMicBlocked) {
            alert(
                "Permissão para acessar o Microfone negada. Por favor configure suas permissões de navagador para habilitar o envio de mensagens de áudio."
            );
        } else {
            setIsRecording(true);
        }
    };

    const saveAudio = (recordedBlob) => {
    };

    // HANDLERS
    const handleFile = async (file) => {

        try {
            let data = new FormData();

            data.append('file', file);

            let res = await Axios.post(`${INTEGRATOR_API_HOST}/repositories/messages`, data, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization': user.token,
                }
            });

            let descriptor = res.data;
            if (typeof descriptor === 'string') descriptor = JSON.parse(res);

            if (!descriptor) throw new Error(`Could not send file`);
            if (descriptor.error) throw new Error(`Could not send file: ${descriptor.error}`);

            let message = {
                id: +new Date(),
                clientId: clientId,
                type: 'attachment',
                sender: {
                    id: user.id,
                    name: user.name,
                },
                sentAt: new Date().toISOString(),
                value: {
                    type: getFileType(file),
                    name: descriptor.name,
                    uri: descriptor.uri,
                    mimeType: descriptor.mimeType,
                },
                key: chatId,
                timestamp: getTimeStamp(),
                status: 'delivered',
            };

            socketEmit("message", message);

            fileInput.current.value = '';
        } catch(err) {
            return alert(err);
        }

    };

    const getTimeStamp = () => {
        let hours = new Date().toISOString().substring(11, 13)
        let minutes = new Date().toISOString().substring(14, 16)

        let offset = (new Date().getTimezoneOffset()) / 60

        let newHour = (Number(hours) - offset) + ''
        if (newHour.length < 2) {
            newHour = '0' + newHour
        }

        return newHour + ':' + minutes
    }

    const getFileType = (file) => {
        if (file.type.includes('audio')) return 'audio';
        if (file.type.includes('image')) return 'image';
        if (file.type.includes('video')) return 'video';
        return 'file';
    }

    const handlePaste = (e) => {
        if (!e.clipboardData) return;
        let items = e.clipboardData.items;

        if (!items || !items.length) return;
        let file;
        for (let i = 0; i < items.length; i++) if (items[i].kind === 'file') {
            file = items[i];
            break;
        }
        if (!file) return;

        return handleFile(file.getAsFile());
    }

    return (
        <>
            <Container focus={isActive} isTyping={isTyping}>
                <EmojiPicker
                    isOpen={isEmojiOpen}
                    close={() => setIsEmojiOpen(false)}
                    setText={(emoji) => {
                        setText((text) => text + emoji);
                        textarea.current.focus();
                    }}
                />

                {enableEmoji ? (
                    <IconBtn
                        isOpen={isEmojiOpen}
                        onClick={() => setIsEmojiOpen((s) => !s)}
                        src={emojiIcon}
                        alt=""
                    />
                ) : null}

                {enableAttach ? (
                    <IconBtn
                        margin="13px -20px 0 26px"
                        onClick={() => fileInput.current.click()}
                        src={attachIcon}
                        alt=""
                    />
                ) : null}
                <input
                    onChange={e => {
                        if (!e) return;
                        let file = e.target.files[0];
                        if (!file) return;
                        return handleFile(file);
                    }}
                    ref={fileInput}
                    type="file"
                    style={{display: "none"}}
                />

                {enableRecord ? (
                    <IconBtn
                        margin="13px -20px 0 26px"
                        onClick={() => startRecording()}
                        isOpen={isRecording}
                        src={micIcon}
                        alt=""
                    />
                ) : null}
                <AudioRecorder
                    isOpen={isRecording}
                    close={() => setIsRecording(false)}
                    saveAudio={saveAudio}
                />

                <Area
                    onKeyPress={(e) =>
                        (e.keyCode || e.which === 13) && !e.shiftKey ? sendMessage(e) : null
                    }
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    onFocus={() => setIsActive(true)}
                    onBlur={() => setIsActive(false)}
                    onPaste={handlePaste}
                    placeholder={placeholder}
                    ref={textarea}
                />

                <Send onClick={sendMessage}>
                    <img src={sendIcon} alt=""/>
                </Send>
            </Container>
        </>
    );
}

export default Textarea;
