import { combineReducers } from "redux";

import chatInfo from './chatInfo'
import users from './user';
import channels from './channel';

export default () =>
    combineReducers({
        chat: chatInfo,
        users: users,
        channels: channels

    });