import React from 'react';

// IMAGES
import logo from '../../assets/55logo.png';
import popupIcon from '../../assets/popupIcon.png';
import videoIcon from '../../assets/videoIcon.png';

// COMPONENTS
import Dots from '../Dots';

// STYLES
import { Container, Icon } from './styles';

function Header({
                  title,
                  clientLogo,
                  exportText,
                  popupText,
                  openVideoCall,
                  user,
                  clientId,
                  chatId,
                  setIsOpen }) {
  const handlePopup = () => {
    alert('Inserir ação popup')
  }

  const handleVideocall = () => {
    openVideoCall()
  }

  return <Container>
    <img src={clientLogo || logo} alt=""/>
    {title || '55PBX - Webchat'}
    {/* @FEATURE toggled off */}
    {/*<Icon onClick={handleVideocall} margin="0 0 0 auto">*/}
    {/*  <img src={videoIcon} alt=""/>*/}
    {/*</Icon>*/}
    {/*<Icon onClick={handlePopup}>*/}
    {/*  <img src={popupIcon} alt=""/>*/}
    {/*</Icon>*/}
    <Icon margin="0 0 0 auto">
      <Dots setBallonOpen={setIsOpen} popupText={popupText} exportText={exportText} user={user} clientId={clientId} chatId={chatId} />
    </Icon>
  </Container>;
}

export default Header;