const INITIAL_STATE = {
	provider: null,
	id: null,
	key: null,
	access_token: null
};

const channels = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'SINGIN':
			return {
				...state,
			};
		case 'LOGOUT':
			return {...state, ...INITIAL_STATE};
		default:
			return state;
	}
}

export default channels;