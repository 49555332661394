/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveMessage } from './actions/chatInfo'

import { startChat, saveID } from './actions/chatInfo'
import manager from './services/socket';
import config from './services/client';

// THEME
import {ThemeProvider} from "styled-components";

// COMPONENTS
import Ballon from "./components/Ballon";

function App() {

    const chatState = useSelector(state => state.chat)
    const dispatch = useDispatch();

    const [visualConfigs, setVisualConfigs] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const [messages, setMessages] = useState([]);
    const [socketOn, setSocketOn] = useState(false);
    const [clientId, setClientId] = useState(null);
    const [chatId, setChatId] = useState(null);
    const [user, setUser] = useState(chatState.user);
    const [socketEmit, setSocketEmit] = useState((key, data) => {
        /**
         * @TODO: Implementar fila offline - localStorage + iosync
         */
    });
    const [socketEvent, setSocketEvent] = useState(null);
    const [ isShown, setIsShown ] = useState(true);

    let socketIO;

    useEffect(() => {
        (async () => {

            console.log('LUGSER', user, chatState);

            if(!clientId) return;

            setIsLoading(true);

            socketIO = manager.getSocket(clientId, user && user.id);

            setSocketEvent(() => {
                return (event, callback) => {
                    socketIO.on(event, callback);
                };
            });

            setSocketEmit(() => {
                return (key, value) => {
                    if (socketIO) {
                        socketIO.emit(key, value);
                    } else {
                        // TODO: 2 - Implementar fila offline - localStorage + iosync'
                    }
                };
            });

            socketIO.on("message", (msg) => {
                /*
                    msg.sender && msg.sender.id && user && msg.sender.id === user.id
                            ? "__self"
                            : (msg.sender || {}).id,
                */
                if (!msg) return;
                
                let now = new Date();
                    dispatch(saveMessage({
                        id: +new Date(),
                        type: msg.type,
                        value: msg.value,
                        sender: {
                            id: msg.sender.id,
                            name: msg.sender.name,
                        },
                        timestamp: `${("00" + now.getHours()).slice(-2)}:${(
                            "00" + now.getMinutes()
                        ).slice(-2)}`,
                        status: "delivered",
                    }))
            });

            socketIO.on("connect", () => {
                setSocketOn(true);
            });

            socketIO.on("disconnect", () => {
                setSocketOn(false);
            });
            
            try {
                let user = await socketIO.auth;
                setUser(user);
                setIsLoading(false);
                dispatch(startChat({ user }, clientId))
            } catch(err) {
                console.error(err);
                // alert("Um erro ocorreu ao tentar autenticar seu usuário.");
                setIsShown(false);
                setIsLoading(false);
            }

            return () => {
                socketIO.disconnect();
            };
        })();
    }, [ clientId ]);

    // THEME VARS
    const [icon, setIcon] = useState(false);
    const [placeholder, setPlaceholder] = useState(false);
    const [title, setTitle] = useState(false);
    const [clientLogo, setClientLogo] = useState(false);
    const [draggable, setDraggable] = useState(false);
    const [enableEmoji, setEnableEmoji] = useState(true);
    const [enableAttach, setEnableAttach] = useState(true);
    const [enableRecord, setEnableRecord] = useState(true);
    const [formButtonName, setFormButtonName] = useState(false);
    const [formTitle, setFormTitle] = useState(false);
    const [formSuccessText, setFormSuccessText] = useState(false);
    const [optionsFormTitle, setOptionsFormTitle] = useState(false);
    const [optionsFormSuccessText, setOptionsFormSuccessText] = useState(false);
    const [exportText, setExportText] = useState(false);
    const [popupText, setPopupText] = useState(false);
    const [theme, setTheme] = useState({
        iconBgcolor: "#448757",
        chatBgcolor: "#FFFFFF",
        chatWidth: "400px",
        chatHeight: "600px",
        headerBgcolor: "#448757",
        titleFontColor: "#FFF",
        receivedMessageBgcolor: "#f0f0f0",
        sentMessageBgcolor: "#f0f0f0",
        messageFontColor: "#171717",
        messageWidth: false,
        sendButtonBgcolor: "#448757",
        textFontColor: "#171717",
        checkButtonColor: "#448757",
        scrollbarTrackColor: "#F5F5F5",
        scrollbarThumbColor: "#7b8285",
    });

    useEffect(() => {
        if (!clientId) getConfigs();
    }, [clientId]);

    useEffect(() => {
        if (chatState.clientId === null) getConfigs();
    }, [chatState.clientId]);

    useEffect(() => {
        setUpTheme();
    }, [visualConfigs]);

    const getConfigs = async () => {
        setIsLoading(true);

        const fetched = await config.getConfig()
        if (!fetched) {
            setIsLoading(false);
            setIsShown(false);
        }

        setClientId(fetched.clientId);
        setVisualConfigs(fetched.settings);
        setChatId(fetched._id);
        setIsLoading(false);
    };

    const setUpTheme = () => {
        if (visualConfigs) {
            let {
                icon,
                icon_bg,
                chat_width,
                chat_height,
                chat_bg,
                placeholder,
                title_title,
                client_logo,
                header_bg,
                sendButtonBgcolor,
                received_message_bg,
                sent_message_bg,
                title_font_color,
                message_font_color,
                text_font_color,
                checkButtonColor,
                scrollbarTrackColor,
                scrollbarThumbColor,
                scrollbarWidth,
                draggable,
                formButtonName,
                formButtonBgcolor,
                formButtonFontColor,
                enable_emoji,
                enable_attach,
                enable_record,
                formTitle,
                formSuccessText,
                optionsFormTitle,
                optionsFormSuccessText,
                exportText,
                popupText,
            } = visualConfigs;

            setTheme({
                iconBgcolor: icon_bg || "#448757",

                chatBgcolor: chat_bg || "#FFFFFF",
                chatWidth: chat_width || "500px",
                chatHeight: chat_height || "600px",

                headerBgcolor: header_bg || "#448757",
                titleFontColor: title_font_color || "#FFF",

                receivedMessageBgcolor: received_message_bg || "#f0f0f0",
                sentMessageBgcolor: sent_message_bg || "#f0f0f0",
                messageFontColor: message_font_color || "#171717",

                textFontColor: text_font_color || "#171717",

                sendButtonBgcolor: sendButtonBgcolor || "#448757", //

                checkButtonColor: checkButtonColor || "#448757", //

                scrollbarTrackColor: scrollbarTrackColor || "#F5F5F5", //
                scrollbarThumbColor: scrollbarThumbColor || "#7b8285", //
                scrollbarWidth: scrollbarWidth || "8px", //

                formButtonBgcolor: formButtonBgcolor || "#BEBEBE", //
                formButtonFontColor: formButtonFontColor || "#171717", //
            });

            setIcon(icon);
            setPlaceholder(placeholder);
            setTitle(title_title);
            setClientLogo(client_logo);
            setFormButtonName(formButtonName); //
            setEnableEmoji(enable_emoji); //
            setEnableAttach(enable_attach); //
            setEnableRecord(enable_record); //
            setFormTitle(formTitle); //
            setOptionsFormTitle(optionsFormTitle); //
            setFormSuccessText(formSuccessText);
            setOptionsFormSuccessText(optionsFormSuccessText);
            setExportText(exportText);
            setPopupText(popupText);
        }
    };

    if (isLoading) {
        return <div/>;
    }

    if (!isShown) return null;
    // if (!clientId) return null;

    return (
        <ThemeProvider theme={theme}>
            <Ballon
                socketIO={socketIO}
                chatId={chatId}
                user={chatState.user} //chatState.user ou user
                // messages={chatState.messages}
                clientId={clientId}
                socketEvent={socketEvent}
                setMessages={setMessages}
                socketOn={socketOn}
                socketEmit={socketEmit}
                icon={icon || null}
                placeholder={placeholder || null}
                title={title || null}
                clientLogo={clientLogo || null}
                draggable={draggable}
                formButtonName={formButtonName}
                enableEmoji={enableEmoji}
                enableAttach={enableAttach}
                enableRecord={enableRecord}
                formTitle={formTitle}
                formSuccessText={formSuccessText}
                optionsFormTitle={optionsFormTitle}
                optionsFormSuccessText={optionsFormSuccessText}
                exportText={exportText}
                popupText={popupText}
            />
        </ThemeProvider>
    );
}

export default App;
