import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  height: auto;

  audio {
    margin: 0 0 -5px;
  }

  a {
    margin: 10px 0;


    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    text-decoration: underline;
    color: #0000EE;
  }
`;

export const Image = styled.img`
  max-width: 100px;
  max-height: 80px;
  
  margin: 0 0 0;

  border-radius: 4px;

  &:hover {
    cursor: pointer;
  }
`;
