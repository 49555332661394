import React from 'react';

// COMPONENTES
import ChatWindow from './components/ChatWindow';
import Header from './components/Header';
import Textarea from './components/Textarea';
import Chat from './components/Chat';
// import tempData from './assets/data';

// STYLES
import {Powered} from './components/Ballon/styles';
import ChatNotification from "./components/ChatNotification";

function Popup({placeholder, title, clientLogo, draggable, formButtonName, enableEmoji, enableAttach, enableRecord, formTitle, formSuccessText, optionsFormTitle, optionsFormSuccessText, exportText}) {

	const messages = [];

	return <>

		<ChatWindow draggable={draggable} isOpen={true}>

			<Header exportText={exportText} title={title} clientLogo={clientLogo}/>

			<Chat formSuccessText={formSuccessText}
			      optionsFormSuccessText={optionsFormSuccessText}
			      formButtonName={formButtonName}
			      formTitle={formTitle}
			      optionsFormTitle={optionsFormTitle}
			      messages={messages}/>

			<ChatNotification />
			<Textarea placeholder={placeholder || 'Digite...'}
			          enableEmoji={enableEmoji} enableAttach={enableAttach}
			          enableRecord={enableRecord}/>

			<Powered>
				<a rel="noopener noreferrer" href="https://www.55pbx.com/" target="_blank">Powered by 55PBX</a>
			</Powered>

		</ChatWindow>
	</>

}

export default Popup;
