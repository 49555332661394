import styled from 'styled-components';

export const Container = styled.div`
  width: ${p => p.theme.chatWidth};
  min-width: 400px;
  height: ${p => p.theme.chatHeight};
  min-height: 400px;
  z-index: 2;

  position: absolute;
  bottom: 140px;
  right: 30px;

  display: ${p => p.isOpen ? 'flex' : 'none'};
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: #00000080;
  border-radius: 5px 5px 0 0;
`;

export const Modal = styled.div`
  width: 200px;
  height: 247px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 0 0;

  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);

  p {
    margin: 26px 0 0px;

    font-size: 12px;
    color: #000000BB;
  }

  h2 {
    margin: 5px 0 -20px;

    font-size: 12px;
    color: #000000BB;
  }
`;

export const CallImg = styled.img`
  width: 80px;
  margin: 9px 0 0;
  z-index: 1;

  animation-name: ring;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;

  @keyframes ring {
    from {transform: rotate(0deg)}
    to {transform: rotate(12deg)}
    to {transform: rotate(-12deg)}
  }
`;

export const Effect = styled.div`
  width: 100px;
  height: 100px;
  margin: 0 0 36px;

  position: absolute;

  background-color: #448757BB;
  border-radius: 100%;

  animation-name: grow, blink;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes blink {
    from {opacity: 1}
    to {opacity: 0.2} 
  }

  @keyframes grow {
    from {transform: scale(0.2,0.2)}
    to {transform: scale(1,1)} 
  }
`;

export const Buttons = styled.div`
  width: 85%;
  margin: 37px 0 0;

  display: flex;
  justify-content: space-around;
  align-items: center;

  button {
    width: 50px;
    height: 50px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 100%;
    border: none;

    img {
      width: 24px
    }
  }
`;


