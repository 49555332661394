import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  
  flex-shrink: 1000;
  
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 10px;

  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: ${p => p.theme.scrollbarWidth};
  }

  &::-webkit-scrollbar-track {
	  background-color: ${p => p.theme.scrollbarTrackColor};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${p => p.theme.scrollbarThumbColor};
  }
`;

export const Anchor = styled.div`
  width: 100%;
  height: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 12px;
`

export const FormContainer = styled.form`
  width: 100%;

  display: flex;
  flex-direction: column;
`;

export const FormTitle = styled.p`
  margin: 6px auto 12px;

  font-size: 16px;
  font-weight: bold;
`;


