import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate }from 'redux-persist/integration/react'
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

// Google Analytics
// import ReactGA from 'react-ga';

import { store, persistor } from './services/store';
import Web from './App';
import Popup from './Popup';

import './styles/main.css';

// ReactGA.initialize('UA-000000-01');
// ReactGA.pageview(window.location.pathname + window.location.search);



ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor} >
        <BrowserRouter>
          <Switch>
            <Route exact path="/popup" component={Popup}/>
            <Route exact path="/" component={Web}/>
            <Redirect from="*" to="/" />
          </Switch>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
