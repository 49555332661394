import React, { useState } from 'react';
import html2canvas from "html2canvas";
import pdfMake from "pdfmake/build/pdfmake";
import WindowBridge from '55tec_react_window_bridge';
import { useDispatch } from 'react-redux';
import { endChat } from '../../services/chat';

// IMAGES
import dotsIcon from '../../assets/dotsIcon.png';

// STYLED
import {Container, Icon, Window, Option} from './styles';
import { finishChat } from '../../actions/chatInfo';

function Dots({
				user,
				clientId,
				chatId,
				exportText,
				popupText,
				setBallonOpen
			}) {

	const [isOpen, setIsOpen] = useState(false)
	const dispatch = useDispatch();
	const handlePrintChat = () => {
		html2canvas(document.getElementById("msg1")).then(canvas => {
			let data = canvas.toDataURL();
			let pdfExportSetting = {
				content: [
					{
						image: data,
						width: 500
					}
				]
			};
			pdfMake.createPdf(pdfExportSetting).download((+new Date()) + ".pdf");
		});
		setIsOpen(false);
	};

	const handleEndChat = () => {
		let end = endChat(clientId);
		if (!!end) return new Error("Error on end attendance")
		dispatch(finishChat(end))
		setBallonOpen(false)
	};

	const handleBridge = status => {
	};

	function handleExport() {
		
	}

	return <WindowBridge
		url='/popup'
		width={300}
		height={500}
		bridge={handleBridge}
		alwaysOnTop={true}
		render={({openPopup}) =>
			(
				<Container>
					<Icon onClick={() => setIsOpen(s => !s)} src={dotsIcon}/>
					<Window isOpen={isOpen}>
						<Option onClick={handlePrintChat}>{exportText || "Exportar Conversa"}</Option>
						<Option onClick={handleEndChat}>{"Encerrar Conversa"}</Option>
						{/*<Option onClick={openPopup}>{popupText}</Option>*/}
					</Window>
				</Container>
			)
		}
	/>;

  const handlePopup = () => {
    setIsOpen(false)
    alert('implementar ação para popup')
  }

  return <Container>
    <Icon onClick={() => setIsOpen(s => !s)} src={dotsIcon} />
    <Window isOpen={isOpen}>
      <Option onClick={handleExport}>{exportText || 'Exportar Conversa'}</Option>
      <Option onClick={handlePopup}>{popupText || 'Abrir Chat em Nova Janela'}</Option>
    </Window>
  </Container>;
}

export default Dots;