import styled from 'styled-components';

export const MessageContainer = styled.div`
  margin: ${p => p.user ? '4px 0 4px auto' : '4px 0'};

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 6px;
`;

export const MessageBody = styled.div`
  width: ${p => p.theme.messageWidth || p.big ? '330px' : p.isImg ? 'auto' : '200px'};
  min-height: 30px;

  display: flex;
  flex-direction: ${p => p.isForm ? 'column' : 'column'};
  justify-content: flex-start;
  align-items: flex-start;
  padding: ${p => p.isForm ? '6px 20px' : '6px 8px'};

  border-radius: 5px;
  border: none;
  background-color: ${p => p.userMessage ? p.theme.sentMessageBgcolor : p.theme.receivedMessageBgcolor};

  font: normal 14px/20px sans-serif;
  color: ${p => p.theme.messageFontColor};
  word-break: break-word;
`;

export const Caret = styled.div`
  width: 0; 
  height: 0; 
  margin:  ${p => p.user ? '5px 0 0 -2px' : '5px -2px 0 0'};

  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;

  border-right: ${p => p.user ? 'none' : '10px solid ' + p.theme.receivedMessageBgcolor};
  border-left: ${p => p.user ? '10px solid ' + p.theme.sentMessageBgcolor : 'none'};
`;

export const Status = styled.div`
  width: 10px;
  height: 10px;
  margin: 0 0 0 4px;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 8px;
    height: 8px;
  }
`;

export const InfoText = styled.p`
  margin: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 10px;
  line-height: 12px;
  color: #000;
  `;

export const Row = styled.div`
  margin: ${p => p.margin ? p.margin : p.isImg ? '-18px 0px 0 -4px' : p.isForm ? '6px -10px -2px auto' : '2px -2px 0 auto'};
  width: 100%;

  position: relative;

  display: ${p => p.display ? 'flex' : 'none'};
  justify-content: flex-end;
  align-items: center;
`;