import styled from 'styled-components';

export const Container = styled.div`
  width: 300px;
  height: ${p => p.isOpen ? '355px' : '0.5px'};
  overflow: hidden;

  position: absolute;
  bottom: 44px;
  left: 8px;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  opacity: ${p => p.isOpen ? '1' : '0'};
  transition: all ease 0.3s;
`;
