import socketIOClient from "socket.io-client";

const SOCKETIOADDRESS = process.env.REACT_APP_SDK_SOCKET_HOST;

class Manager {

    constructor() {
        this.map = {}
    }

    getSocket(clientId, customerId) {
        if (this.map[clientId]) {
            return this.map[clientId]
        }

        var socketIO;

        socketIO = socketIOClient(SOCKETIOADDRESS + `?clientId=${clientId}&customerId=${customerId}`);

        socketIO.auth = new Promise((resolve, reject) => {
            let authenticated = false;
            socketIO.on("auth", (data) => {
                resolve(data)
                authenticated = true;
            });

            setTimeout(() => {
                if (authenticated) return;
                reject(new Error("Authentication timed out"))
            }, 30000);
        })

        this.map[clientId] = socketIO;
        return socketIO;
    }

}

let factory = new Manager();

export default factory;