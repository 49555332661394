import manager from '../services/socket';
import { CHATINFO_START_CHAT, CHATINFO_FINISH_CHAT, CHATINFO_SAVE_MESSAGE } from '../constants/action-type'

const INITIAL_STATE = {
    messages: [],
    user: null,
    isActive: false,
    clientId: null
};

let socketIO;

//finish chat
const chatInfo = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case CHATINFO_START_CHAT:
            return {...state, user: action.payload.user, isActive: true, clientId: action.payload.id}

        case CHATINFO_SAVE_MESSAGE:
            let newMessages = [...state.messages, action.payload ? action.payload.message : ""]

            // if(newMessages != "" && newMessages != undefined && newMessages != null)
            return {...state, messages: newMessages }

            
        case CHATINFO_FINISH_CHAT:
            return {...INITIAL_STATE}

        default:
			return state;    
	}
}

export default chatInfo;