import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FormContainer = styled.form`
  width: 100%;

  display: flex;
  flex-direction: column;

  transition: all ease 3s;
`;

export const FormTitle = styled.p`
  margin: 6px auto 12px;

  font-size: 16px;
  font-weight: bold;
`;

export const FormButton = styled.button`
  background-color: ${p => p.theme.formButtonBgcolor};

  /* color: ${p => p.theme.formButtonFontColor}; */
`;

export const SentImage = styled.img`
  width: 60px;
  height: 60px;
  margin: 0 auto;
`;