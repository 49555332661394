import React, { useState } from 'react';

// IMAGES
import downloadIcon from '../../assets/downloadIcon.png';

// COMPONENTS
import ImageModal from '../ImageModal';

// STYLED
import { Container, Image } from './styles';

function Attach({user, item}) {

  const [isOpen, setIsOpen] = useState(false)

  let uri = item.value.uri + `?token=${user.token}`;

  if(item.value.type === 'image') {
    return <Container>
      <Image onClick={() => setIsOpen(s => !s)} src={uri} alt={item.value.name} title='Clique para ampliar'/>
      <ImageModal close={() => setIsOpen(s => !s)} isOpen={isOpen} img={uri}/>
    </Container>;
  }

  if(item.value.type === 'audio') {
    return <Container>
      <audio src={uri} controls></audio>
    </Container>;
  }

  if (item.value.type === 'video') {
    return <Container>
      <video src={uri} controls width="150px" height="150px"></video>
    </Container>
  }

  return <Container>
    <a rel="noopener noreferrer" target="_blank" href={uri} download={item.value.name}>
      <Image src={downloadIcon} alt=""/>
      {item.value.name.match(/.{1,24}/g).join(' ')}
    </a>
  </Container>;
}

export default Attach;